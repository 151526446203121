import React from 'react';
import styled from 'styled-components';
import { Heading, Text, Button } from '@atoms';
import { OrderedList } from '@components/RichTextComponents/OrderedList';
import { UnorderedList } from '@components/RichTextComponents/UnorderedList';
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS, MARKS } from '@contentful/rich-text-types';
import { theme } from '@theme';

///////--COMPONENTS--///////
import { ContentfulImage } from '@components/ContentfulImage';
import { QuoteBlock } from '@components/RichTextComponents/QuoteBlock';

const headingStyles = {
  padding: '-15px 0px 8px 0px'
}

export const switchbackResolveRichText = (unresolvedContentObject, subheadingBullet) => {

  const Quote = styled.div`
    padding: 24px;
    margin: 30px 20px;
    border-left: 3px solid #69D1F0;
    color: #002DC2;
    font-size: 22px;
    line-height: 30px;
    @media (max-width: 600px) {
      font-size: 16px;
      line-height: 24px;
    }
  `

  const bodyContent = JSON.parse(unresolvedContentObject?.raw);
  const references = unresolvedContentObject?.references;
  const renderOptions = {
    // renderText: text => text.split('\n').flatMap((text, i) => [i > 0 && <br />, text]),
    renderMark: {
      [MARKS.BOLD]: text => <Text as="span" style={{ fontWeight: 'bold' }}>{text}</Text>,
      [MARKS.ITALIC]: text => <Text as="span" style={{ fontStyle: 'italic' }}>{text}</Text>,
      [MARKS.UNDERLINE]: text => <Text as="span" style={{ textDecoration: 'underline' }}>{text}</Text>,
    },
    renderNode: {
      [BLOCKS.HEADING_1]: (node, children) => <Heading style={{ ...headingStyles }} headingSize='H1' className="mt-2">{children}</Heading>,
      [BLOCKS.HEADING_2]: (node, children) => {
        return (
          <div>
            <div style={{ marginTop: '80px', height: '4px', background: theme.colors.primary, borderRadius: '50px', width: '48px' }} />
            <Heading style={{ ...headingStyles, marginBottom: '20px' }} headingSize='H2' className="mt-2">
              <span id={children}>{children}</span>
            </Heading>
          </div>
        )
      }
      ,
      [BLOCKS.HEADING_3]: (node, children) => <Heading style={{ ...headingStyles, color: '#002DC2', marginTop: '40px', marginBottom: '20px' }} headingSize='H3' className="mt-2"><span id={children}>{children}</span></Heading>,
      [BLOCKS.HEADING_4]: (node, children) => <Heading style={{ ...headingStyles }} headingSize='H4' className="mt-2">{children}</Heading>,
      [BLOCKS.HEADING_5]: (node, children) => <Heading style={{ ...headingStyles }} headingSize='H5' className="mt-2">{children}</Heading>,
      [BLOCKS.HEADING_6]: (node, children) => <Heading style={{ ...headingStyles }} headingSize='H6' className="mt-2">{children}</Heading>,
      [BLOCKS.PARAGRAPH]: (node, children) => <p style={{ marginBottom: '16px' }}>{children}</p>,
      [BLOCKS.UL_LIST]: (node, children) => {
        if (subheadingBullet === 'check-mark') {
          return (
            <UnorderedList type='checkmark' children={children} />
          )
        } else if (subheadingBullet === 'persona-check') {
          return (
            <UnorderedList type="persona" children={children} />
          )
        } else if (subheadingBullet === "dark-check") {
          return (
            <UnorderedList type="dark-check" children={children} />
          )
        } else if (subheadingBullet === "check-line") {
          return (
            <UnorderedList type="check-line" children={children} />
          )
        } else {
          return (
            <UnorderedList type='standard' children={children} />
          )
        }
      },
      [BLOCKS.OL_LIST]: (node, children) => <OrderedList type='standard' children={children} />,
      [BLOCKS.QUOTE]: (node, children) => <Quote>{children}</Quote>,
      [BLOCKS.EMBEDDED_ASSET]: (node, children) => {
        const id = node.data.target.sys.id;
        const asset = references?.filter(subComp => subComp?.contentful_id === id);
        if (asset && asset[0]?.file.contentType.includes('image')) {
          return null;
        } else {
          return null;
        }
      },
      [BLOCKS.EMBEDDED_ENTRY]: (node, children) => {
        const id = node.data.target.sys.id;
        const component = references?.filter(subComp => subComp?.contentful_id === id);
        if (component && component[0]?.internal?.type === 'ContentfulComponentImage') {
          return (
            <div data-comp='contentfulImage' style={{ padding: '32px 0px' }}>
              <ContentfulImage imageData={component[0]} showCaption={true} />
            </div>
          )
        } else if (component && component[0]?.sys?.contentType?.sys?.id === "componentCta") {
          return (
            <Button
              ctaVariant='primary'
              ctaLink={component[0]?.link}
            >
              {component[0]?.label}
            </Button>
          )
        } else if (component && component[0]?.internal?.type?.toLowerCase().includes('quote')) {
          return (
            <div style={{ padding: '32px 0px' }}>
              <QuoteBlock quoteData={component[0]} />
            </div>
          )
        } else {
          console.log('There was an error trying to match and determine components for Rich Text in utils/resolve-rich-text/swichbackResolveRichText.jsx')
        }
      },
    }
  };
  return documentToReactComponents(bodyContent, renderOptions);
}
